import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as reset from "./normalize.css"
import * as assetstyles from "../assets/assets.css"
import * as styles from "./index.module.css"
import ColoredSeparator from "../components/colored_sep"
import IconFB from "-!svg-react-loader?props[]=className:icon_wrap!../assets/facebook.svg"
import IconIG from "-!svg-react-loader?props[]=className:icon_wrap!../assets/instagram.svg"
import IconFBW from "-!svg-react-loader?props[]=className:icon_wrap!../assets/facebook_white.svg"
import IconIGW from "-!svg-react-loader?props[]=className:icon_wrap!../assets/instagram_white.svg"
import SEO from "../components/seo"

const privacyStyle = {
	fontSize: "0.60em",
	textAlign: "center"
}


// markup
const IndexPage = () => {
	return (
		<>
		<header>
			<StaticImage src="../images/cdm_logo.png" alt="Centro del Mondo Logo" />
		</header>
		<main>
			<SEO title="Home"/>
			<section className={styles.hero}>
				<StaticImage src="../images/comics_bg.jpg" alt="Comics Background" className={styles.hero_thumb} imgClassName={styles.comics_bg_s} />

				<div className={styles.hero_content}>
					<h1 className={styles.comics_bg_text}>LA TUA FUMETTERIA A FOLIGNO</h1>
				</div>
			</section>

			<section className={styles.half}>
				<article className={styles.article_wrapper}>
					<div className={styles.withus_wrap}>
						<div>
							<h2 className={styles.article_title}>COSA TROVERAI DA NOI</h2>
						</div>
						<div className={styles.article_text}>
							Non solo manga e comics ma anche una sezione dedicata alle carte collezionabili
							(Magic, Pokemon e Yu-gi-oh!), alle figures (Funko POP, Action figures) dei tuoi personaggi preferiti
							ed ai giochi di ruolo (D&D, Pathfinder e Vampiri) e da tavolo
						</div>
					</div>
				</article>
			</section>

			<section className={styles.half + " " + styles.image_stack}>
				<StaticImage src="../images/myhero.png" alt="My Hero Academia Comic" className={styles.image_stack_item + " " + styles.top_left} objectFit="contain"/>
				<StaticImage src="../images/ruffy_stand.png" alt="Ruffy Statue" className={styles.image_stack_item + " " + styles.top_right} objectFit="contain"/>
				<StaticImage src="../images/tabletop.png" alt="Zombicide Tabletop" className={styles.image_stack_item + " " + styles.bottom_left} objectFit="contain"/>
				<StaticImage src="../images/pk_cards.png" alt="Pokemon Trading Cards" className={styles.image_stack_item + " " + styles.bottom_right} objectFit="contain"/>
			</section>

			<section className={styles.hero}>
				<StaticImage src="../images/tabletop_bg.png" alt="Comics Background" className={styles.hero_thumb} imgClassName={styles.comics_bg_s} />
			</section>

			<section className={styles.half + " " + styles.image_stack}>
				<StaticImage src="../images/store_card_front.jpg" alt="Discount Card Front" className={styles.image_stack_item + " " + styles.top_left__discount} objectFit="contain"/>
				<StaticImage src="../images/store_card_back.jpg" alt="Discount Card Back" className={styles.image_stack_item + " " + styles.top_right__discount} objectFit="contain"/>
			</section>

			<section className={styles.half}>
				<article className={styles.article_wrapper}>
					<div className={styles.discount_wrap}>
						<h2 className={styles.article_title}>TANTI SCONTI PER TE</h2>

						<div className={styles.article_text}>
							Con la nostra tessera fedeltà riceverai sempre il 5% di sconto su tutto l'assortimento manga e comics!
							In più, ogni euro di spesa, accumulerai punti: raggiunta la soglia dei 60 punti avrai uno sconto di 5 euro.
							Se non trovi il prodotto che cerchi, non ti preoccupare!
							Te lo ordiniamo immediatamente ed in pochi giorni potrai venirlo a ritirare in negozio,
							chiedi direttamente in cassa tutto ciò che ti serve!
						</div>
					</div>
				</article>
			</section>

			<section>
				<ColoredSeparator color="#F59E2E" height="5px" width="90vw"/>
				<article className={styles.article_wrapper}>
					<div className={styles.ebay_wrap}>
						<div className={styles.ebay_content}>
							<h2 className={styles.article_title} style={{width:"100%"}}>ACQUISTA ONLINE...</h2>
							<div className={styles.article_text}>
								Potrai acquistare i nostri prodotti, nuovi o usati, tramite i negozi <b>Ebay</b> e <b>Manga Comics Market</b> o scansionando il QR Code!
							</div>
							<div className={styles.qr_sect}>
								<div className={styles.qr_wrap}>
									<a className={styles.qr_text} href="https://www.ebay.it/usr/fumetteriacentrodelmondo" target="_blank" rel="noreferrer"> Ebay </a>
									<StaticImage src="../images/qrcode_ebay.png" alt="Ebay QR Code" objectFit="contain" />
								</div>
								<div className={styles.qr_wrap}>
									<a className={styles.qr_text} href="https://www.mangacomicsmarket.it/venditore/centro-del-mondo-di-minni-emanuele/17055.htm" target="_blank" rel="noreferrer"> Manga Comics Market </a>
									<StaticImage src="../images/qrcode_mangacomics.png" alt="Manga Comics Market QR Code" objectFit="contain" width="450" heigth="450" />
								</div>

							</div>
						</div>
					</div>
				</article>

				<article className={styles.article_wrapper}>
					<div className={styles.ebay_wrap}>
						<div className={styles.ebay_content}>
							<h2 className={styles.article_title}>...OPPURE CONTATTACI!</h2>
							<div className={styles.article_text}>
								Se non trovi il prodotto che cerchi contattaci in uno dei seguenti modi:
								<ul>
									<li>
										Chiamando o scrivendo su Whatsapp al <b>3518004159</b>
									</li>
									<li>
										Scrivendo su messenger alla nostra pagina <a href="https://www.facebook.com/FumetteriaCentroDelMondoFoligno" target="_blank" rel="noreferrer" style={{color: "#175C7B"}}> Facebook </a>
									</li>
									<li>
										Inviando una <a href="mailto:fumetteriacentrodelmondo@outlook.it" target="_blank" rel="noopener noreferrer" style={{color: "#175C7B"}}> email</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</article>

				<ColoredSeparator color="#F59E2E" height="5px" width="90vw"/>
			</section>

			<section>
				<div className={styles.sect_align}>
					<div className={styles.social_wrap}>
						<h2 className={styles.article_title}>SIAMO ANCHE SOCIAL!</h2>
						<div className={styles.article_text} style={{textAlign:"center"}}>
							Ricordati inoltre di seguirci sui nostri profili social dove pubblichiamo
							le novità in uscita e le promozioni speciali da noi proposte.
						</div>
						<div className={styles.social_icons_wrap} style={{marginTop:"5%"}}>
							<a href="https://www.facebook.com/Fumetteria-Centro-del-Mondo-108577398506841" target="_blank" rel="noreferrer">
								<IconFB />
							</a>
							<a href="https://www.instagram.com/fumetteriacentrodelmondo/" target="_blank" rel="noreferrer" style={{marginLeft:"1.5em"}}>
								<IconIG />
							</a>
						</div>
					</div>
				</div>
			</section>
		</main>
		<footer className={styles.hero_small}>
			<StaticImage src="../images/footer_bg.png" alt="Footer Background" className={styles.hero_thumb} imgClassName={styles.comics_bg_s} />

			<div className={styles.hero_content + " " + styles.footer_width}>
				<h3 className={styles.footer_title}>CONTATTI</h3>
				<div className={styles.footer_text}>Via Nazario Sauro 4/c - Foligno</div>
				<div className={styles.footer_text}>(Presso le “Scale di Porta Romana”, piano terra, entrata lato Corso Cavour)</div>

				<div className={styles.footer_title}>T. / Whatsapp +39 351 8004159</div>
				<div className={styles.footer_title} style={{wordBreak:"break-all"}}>M. <a href="mailto:fumetteriacentrodelmondo@outlook.it" target="_blank" rel="noopener noreferrer">fumetteriacentrodelmondo@outlook.it</a></div>

				<ColoredSeparator color="#FFFFFF" height="1px" width="100%" margin="5% 0 2% 0"/>

				<div className={styles.footer_social_wrap}>
					<div className={styles.footer_text} style={{fontSize:"0.70em"}}>Centro del Mondo srl - P.IVA IT 03826380549 - Web Design by 2SDesign - Web Dev by Juse</div>

					<div className={styles.social_icons_wrap}>
						<a href="https://www.facebook.com/Fumetteria-Centro-del-Mondo-108577398506841" target="_blank" rel="noreferrer">
							<IconFBW />
						</a>
						<a href="https://www.instagram.com/fumetteriacentrodelmondo/" target="_blank" rel="noreferrer" style={{marginLeft:"0.8em"}}>
							<IconIGW />
						</a>
					</div>
				</div>
				<Link to="/privacy_policy" style={privacyStyle} className={styles.footer_text}> - Privacy Policy -</Link>
			</div>
		</footer>
		</>
	)
}

export default IndexPage
