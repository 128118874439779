import PropTypes from "prop-types"
import React from "react"


/**
 * Horizontal line with color used to separate content
 */
const ColoredSeparator = ({ color, height, width, margin, customWrapperCname }) => (
	<hr
		className={customWrapperCname}
		style={{
			color: color,
			backgroundColor: color,
			height: height,
			width: width,
			margin: margin
		}}
	/>
);

ColoredSeparator.propTypes = {
	color: PropTypes.string,
}

ColoredSeparator.defaultProps = {
	color: 'black',
}

export default ColoredSeparator
