// extracted by mini-css-extract-plugin
export var article_text = "index-module--article_text--saQvk";
export var article_title = "index-module--article_title--PBNXE";
export var article_wrapper = "index-module--article_wrapper--K+5Wc";
export var bottom_left = "index-module--bottom_left--Npque";
export var bottom_right = "index-module--bottom_right--KT0AY";
export var comics_bg_s = "index-module--comics_bg_s--CjtNy";
export var comics_bg_text = "index-module--comics_bg_text--CrNo-";
export var discount_wrap = "index-module--discount_wrap--v5ulC";
export var ebay_content = "index-module--ebay_content--pKqCN";
export var ebay_wrap = "index-module--ebay_wrap--+hVzO";
export var footer_social_wrap = "index-module--footer_social_wrap--wS+Sb";
export var footer_text = "index-module--footer_text--SpuLL";
export var footer_title = "index-module--footer_title--D-y6l";
export var footer_width = "index-module--footer_width--I0GfX";
export var half = "index-module--half--cqVSg";
export var hero = "index-module--hero--RXViO";
export var hero_content = "index-module--hero_content--dQPfu";
export var hero_content_center = "index-module--hero_content_center--Vief1";
export var hero_small = "index-module--hero_small--AUATQ";
export var hero_thumb = "index-module--hero_thumb--YCVle";
export var image_stack = "index-module--image_stack--zyYTw";
export var image_stack_item = "index-module--image_stack_item--ZEJ8s";
export var overlap_imgs = "index-module--overlap_imgs--oQrx8";
export var qr_sect = "index-module--qr_sect---W1qj";
export var qr_text = "index-module--qr_text--Pw2H8";
export var qr_wrap = "index-module--qr_wrap--WXG8y";
export var sect_align = "index-module--sect_align--ZaLTp";
export var sect_film = "index-module--sect_film--5v9y3";
export var sect_half = "index-module--sect_half--5U6sT";
export var social_icons_wrap = "index-module--social_icons_wrap--U3osl";
export var social_wrap = "index-module--social_wrap--Q2L8U";
export var top_left = "index-module--top_left--DR7Dl";
export var top_left__discount = "index-module--top_left__discount--EW0pa";
export var top_right = "index-module--top_right--tP6we";
export var top_right__discount = "index-module--top_right__discount--8c3Fh";
export var withus_wrap = "index-module--withus_wrap--wFB2s";